import { useIsAuthenticated } from "@azure/msal-react"
import { ImmutableObject } from "@hookstate/core"
import StarIcon from "@mui/icons-material/Star"
import { Alert } from "@mui/material"
import React, { FC } from "react"

import { AdvancedForms } from "src/components/common/advancedForm"
import { AdvancedFormFieldDependency } from "src/components/common/advancedForm/AdvancedFormField"
import { Button } from "src/components/common/button"

import { create } from "src/helpers/bem"
import { deepClone } from "src/helpers/deepClone"
import { formatPrice } from "src/helpers/price"

import { useLocale } from "src/states/locale"
import { addNotification } from "src/states/notifications"
import {
  useCarts,
  useCart,
  mergeCart,
  getCartProductAmount,
  CartProduct,
} from "src/states/shop/carts"
import { mergeProductAttributes } from "src/states/shop/productAttributes"
import { Product } from "src/states/shop/products"
import {
  ProductVariant,
  useProductVariants,
} from "src/states/shop/productVariants"

import { TranslationMessages } from "src/translations"

import styles from "./ShopDetailHeaderContent.module.scss"

const bem = create(styles, "ShopDetailHeaderContent")

export type ShopDetailHeaderContentProps = {
  product: ImmutableObject<Product>
  productVariant: ImmutableObject<ProductVariant>
  setProductVariant: React.Dispatch<
    React.SetStateAction<ImmutableObject<ProductVariant>>
  >
  messages: TranslationMessages
}

export const ShopDetailHeaderContent: FC<ShopDetailHeaderContentProps> = ({
  product,
  productVariant,
  setProductVariant,
  messages,
}) => {
  const m = messages.pages.shop.detail.header.content
  const locale = useLocale()
  const isAuthenticated = useIsAuthenticated()
  const { variants } = useProductVariants()
  const { currentCartId } = useCarts()
  const cart = useCart()

  const cartProduct = cart.products.find(
    (cartProduct) => cartProduct.productVariant?.id === productVariant.id,
  )

  const displayVariants = productVariant.isOptional !== true && (
    variants &&
    (variants.length > 1 || (variants.length === 1 && productVariant?.id !== product?.id))
  );

  const handleAddToCart = () => {
    if (!cartProduct) {
      mergeProductAttributes({
        open: true,
        product: deepClone<Product>(product),
        productVariant: deepClone<ProductVariant>(productVariant),
        form: [deepClone<AdvancedForms>(productVariant.productAttributesForm)],
        dependencies: deepClone<AdvancedFormFieldDependency[]>(
          productVariant.productAttributeDependencies,
        ),
      })
    } else {
      mergeCart(cartProduct, (amount) => amount + 1)
      addNotification({
        autoclose: true,
        variant: "success",
        content: messages.pages.shop.cart.snackbar.changed,
      })
    }
  }

  const isCartInactive = !currentCartId || currentCartId === "0"
  const isMaxAmountReached =
    getCartProductAmount(cartProduct as ImmutableObject<CartProduct>) >=
    (cartProduct?.productVariant?.maximumOrderAmount ?? 1000000)
  const buttonDisabled = isCartInactive || isMaxAmountReached
  const buttonTitle = productVariant.productAttributesForm?.fields?.some(
    (pa) => pa.field === "Amount",
  )
    ? cartProduct
      ? m.button.titleAdd
      : m.button.title
    : m.button.titleNoAdd

  return (
    <div className={bem()}>
      <div className={bem("content")}>
        {product?.tag && <p className={bem("product__tag")}>{product.tag}</p>}
        {product.name && productVariant.name && (
          <>
            {product.hasVariants && (
              <h6 className={bem("content__title")}>{product.name}</h6>
            )}
            <h1 className={bem("content__title")}>
              {product.hasVariants ? productVariant.name : productVariant.isOptional === true ? productVariant.name : product.name}
            </h1>
          </>
        )}
        {productVariant?.note && (
          <div className={bem("product__note")}>{productVariant.note}</div>
        )}
      </div>
      {productVariant?.price != null && (
        <div className={bem("price")}>
          <div className={bem("price__wrapper")}>
            <div className={bem("price__value")}>
              {formatPrice(productVariant.price, locale)}
            </div>
            {productVariant?.unit && (
              <div className={bem("price__unit")}>
                &nbsp;/&nbsp;{productVariant?.unit}
              </div>
            )}
          </div>
        </div>
      )}
      <div className={bem("price")}>
        {productVariant?.priceLabel && (
          <div className={bem("price__label")}>{productVariant.priceLabel}</div>
        )}
        {productVariant?.priceInfo && (
          <div className={bem("price__info")}>{productVariant.priceInfo}</div>
        )}
      </div>
      {displayVariants ? (
        <div className={bem("variants")}>
          {variants.map((variant, i) => (
            <button
              key={`product-variant-${i}`}
              className={bem("variant", {
                "is-active": productVariant.id === variants[i].id,
              })}
              onClick={() => setProductVariant(variants[i])}
            >
              {variant?.favorite ? (
                <StarIcon className={bem("variant__favorite")} />
              ) : null}
              <div className={bem("variant__title")}>{variant?.name}</div>
              <div className={bem("variant__price")}>
                {formatPrice(variant.price, locale)}{" "}
                {variant?.unit && (
                  <i className={bem("variant__price__unit")}>
                    / {variant.unit}
                  </i>
                )}
              </div>
            </button>
          ))}
        </div>
      ) : null}

      <div className={bem("price")}>
        {productVariant.mandatoryProducts &&
          productVariant.mandatoryProducts.length > 0 && (
            <>
              <div className={bem("price__label")}>
                {m.mandatoryProductLabel}
              </div>
              <div className={bem("price__mandatory")}>
                {productVariant.mandatoryProducts.map((p, index) =>
                  p.quantity != null ? (
                    <div key={index}>
                      {p.quantity} x {p.name}
                    </div>
                  ) : (
                    <div key={index}>{p.name}</div>
                  ),
                )}
              </div>
            </>
          )}
      </div>
      <div className={bem("button")}>
        <Button
          disabled={buttonDisabled}
          title={buttonTitle}
          variant="contained"
          onClick={handleAddToCart}
        />
        {buttonDisabled && (
          <Alert
            className={bem("button__helper")}
            severity="info"
            variant="outlined"
          >
            {isAuthenticated
              ? isCartInactive
                ? m.button.helper
                : isMaxAmountReached
                ? m.button.maxAmountReached
                : ""
              : m.button.helper1}
          </Alert>
        )}
      </div>
    </div>
  )
}
